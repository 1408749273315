import React, { Dispatch, SetStateAction } from 'react';
import ls from 'localstorage-slim';
import './App.css';
import StateSelect from './components/ui/StateSelect';
import RenderOffers from './components/ui/RenderOffers';





/*
declare global {
  interface Window { 
    offersWidgetNamespace:any,
    
  }
}*/

function App() {
  const [state, setState] = React.useState('default');

  React.useEffect(() => {
    
    const getUserGeoData = () => {
      const cachedState = ls.get('sportsbookOffersState');
      //console.log(window.offersWidgetNamespace.offersWidgetSportsbook);

      if (cachedState) {
        setState(JSON.parse(String(cachedState)));
      } else {
        setNewUserGeoData()
        .catch(console.error);
      }
    }

    const setNewUserGeoData = async () => {
        
        const defaultGeoUrl = 'https://ipgeolocation.abstractapi.com/v1/?api_key=8cb6d535dd5b435f8d103f8084dbe11d';
      
        const data = await fetchGeoData(defaultGeoUrl);
        
        
        setState(data.region);
        cacheUserGeoData(data.region);


        
    }

    

    const fetchGeoData = async (url:string) => {
      const response = await fetch(url);
      let data = await response.json();
      

      if (!data.region) {
          data = await fetchBackupGeoData();
        }
        
      

      return data;

    }

    const fetchBackupGeoData = async () => {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      

      if (!data.state) {
        data.state = 'default';
      }
      if (data.country_code !== 'US'  && data.country_code !== 'CA') {
        data.state = 'default';
      }

      data.region = data.state;

      return data; 
    }

    const cacheUserGeoData = (userState:string) => {
      const ONEHOUR = 3600;
      const FIVEDAYS = 432000;

      if (userState==='default') {
        ls.set('sportsbookOffersState', JSON.stringify(userState), { ttl: ONEHOUR } );}
        else {
          ls.set('sportsbookOffersState', JSON.stringify(userState), { ttl: FIVEDAYS } );
        }

        
    }

    getUserGeoData();
    
  }, [])

  const onStateChanged = (state:string) => {
    setState(state);
  }
  const uniqid = Math.random().toString(36).slice(2, 7);
  const root = document.getElementById('root');
  
  

  return (
    <div className="App" id={uniqid}>

      
      
      <StateSelect state={state} setState={setState}/>
      <RenderOffers state={state}/>
      
      
      
    </div>
  );
}

export default App;
