import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

export default function OffersTemplate({parsedData, index}: OfferProps ) {
    return (
        <Grid 
        key={index} 
        container 
        //spacing={4}
        sx={{ 
          marginTop:'10px',
          marginBottom:'5px',
          padding:'10px',
          paddingBottom:'5px',
          border:'1px solid silver',
          borderRadius:'8px',
          boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
          backgroundColor: index==0 ? 'rgba(58, 197, 69, .075)' : ''}}
        >
        
        <Grid xs= {2.2} md={.6}
        sx={{alignItems:'center',
        justifyContent:'start',
          display:'flex',
          marginRight:'7px'
        }}>
          <Link href={parsedData.Url} className="sportsbook_widget_link">
            <Box
            component="img"
            src={parsedData.imageUrl}
            sx={{
                //height: {xs:55, md:75},
                width: {xs:50, md:50},
                borderRadius:"10px",
                boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                
                maxWidth: "100%",
                objectFit: "scale-down",
            }}
            />
            </Link>

          </Grid>
          <Grid 
          container
          xs={9} md={6.9}
          direction="column"
          //spacing={0.5}
          sx={{
            textAlign:'left',
            justify:'flex-start'

          }}>

            <Typography sx={{
              textTransform:'uppercase', 
              fontWeight:'900', 
              fontSize:{xs: '.75rem', md:'.75rem'}, 
              opacity:'.55', 
              whiteSpace:'nowrap',
              marginBottom:'1px'}}>

                {parsedData.Sportsbook}
              </Typography>
              <Link href={parsedData.Url} className="sportsbook_widget_link">
              <Typography sx={{
                fontWeight:'bold',
                fontSize:{xs:'.93rem',md:'1rem'},
                textDecoration:'none',
                color:'black',
                '&:hover': {color:'#39D041'},
              }}>
                {parsedData.Offer}
              </Typography>
              </Link>
            <Box 
            component="div" 
            className="subscript-text" 
            id="gambling-disclaimer"
            sx={{ display: { xs: 'none', md: 'block' }, fontSize:'9px', opacity:'.65', marginTop:'3px' }} 
            >
              {parsedData.RGMessage}
            </Box>
            
            
          </Grid>
          
          
          
          <Grid xs={5} md={1.8}
          sx={{
            marginTop: {xs:'20px', md:0},
            //marginBottom: {xs:'10px', md:0}
            textAlign:'left',
            alignSelf:'start'
          }}>
          {parsedData.Code.length > 0 &&
            <Tooltip title="COPY">
                <Button sx={{
                    marginBottom:'-10px',
                    fontSize: ".75rem",
                    paddingLeft:0
                }}
                variant="text"
                onClick={() => { 
                navigator.clipboard.writeText(parsedData.Code)
                
                }}>
                    {parsedData.Code}
                </Button>
            </Tooltip>
          }
          {parsedData.Code.length > 0 ?
          <Typography sx={{fontSize:'10px', opacity:'.65',paddingTop:'2px'}}>Copy Code</Typography> : 
          <Typography sx={{fontSize:'10px', opacity:'.65',paddingTop:'15px'}}>No Code Necessary</Typography>
        }
            
          </Grid>
          <Grid xs={7} md={2.4}
          sx={{
            textAlign:'right',
            marginTop: {xs:'20px', md:0}
          }}>
          <Link href={parsedData.Url} className="sportsbook_widget_link">
            <Button 
            sx={{
                backgroundColor: '#39D041',
                width:'100%',
                marginBottom:"10px",
                boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                fontWeight:'bold',
                fontSize:'1rem',
                animation: index===0 ? 'pulse-delay 4s linear infinite alternate' : 'none',
                '@keyframes pulse-delay': {
                  'from, 40%': {
                    backgroundColor: '#39D041'
                  },
                  '60%, to': {
                    backgroundColor: '#24982a'
                  }
                  },
                '&:hover': {
                    
                    backgroundColor: '#39D041',
                    animation:'none'
                 }
            }}
            variant="contained">Get ${parsedData.offerValue} 💰</Button>
            </Link>
            
            
            
    
          </Grid>
          <Box 
            component="div" 
            className="subscript-text" 
            id="gambling-disclaimer"
            sx={{ display: { xs: 'block', md: 'none' }, fontSize:'9px', opacity:'.65', marginBottom:'15px', marginTop:'5px' }} 
            >
              {parsedData.RGMessage}
            </Box>
          
        </Grid>
    )
}