import * as React from 'react';
import './MuiClassNameSetup';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

const states = [
    {
      "stateAbbreviation": "ALL",
      "state": "default"
    },
    {
      "stateAbbreviation": "AK",
      "state": "Alaska"
    },
    {
      "stateAbbreviation": "AL",
      "state": "Alabama"
    },
    {
      "stateAbbreviation": "AR",
      "state": "Arkansas"
    },
    {
      "stateAbbreviation": "AZ",
      "state": "Arizona"
    },
    {
      "stateAbbreviation": "CA",
      "state": "California"
    },
    {
      "stateAbbreviation": "CO",
      "state": "Colorado"
    },
    {
      "stateAbbreviation": "CT",
      "state": "Connecticut"
    },
    {
      "stateAbbreviation": "DC",
      "state": "District of Columbia"
    },
    {
      "stateAbbreviation": "DE",
      "state": "Delaware"
    },
    {
      "stateAbbreviation": "FL",
      "state": "Florida"
    },
    {
      "stateAbbreviation": "GA",
      "state": "Georgia"
    },
    {
      "stateAbbreviation": "GU",
      "state": "Guam"
    },
    {
      "stateAbbreviation": "HI",
      "state": "Hawaii"
    },
    {
      "stateAbbreviation": "IA",
      "state": "Iowa"
    },
    {
      "stateAbbreviation": "ID",
      "state": "Idaho"
    },
    {
      "stateAbbreviation": "IL",
      "state": "Illinois"
    },
    {
      "stateAbbreviation": "IN",
      "state": "Indiana"
    },
    {
      "stateAbbreviation": "KS",
      "state": "Kansas"
    },
    {
      "stateAbbreviation": "KY",
      "state": "Kentucky"
    },
    {
      "stateAbbreviation": "LA",
      "state": "Louisiana"
    },
    {
      "stateAbbreviation": "MA",
      "state": "Massachusetts"
    },
    {
      "stateAbbreviation": "MD",
      "state": "Maryland"
    },
    {
      "stateAbbreviation": "ME",
      "state": "Maine"
    },
    {
      "stateAbbreviation": "MI",
      "state": "Michigan"
    },
    {
      "stateAbbreviation": "MN",
      "state": "Minnesota"
    },
    {
      "stateAbbreviation": "MO",
      "state": "Missouri"
    },
    {
      "stateAbbreviation": "MS",
      "state": "Mississippi"
    },
    {
      "stateAbbreviation": "MT",
      "state": "Montana"
    },
    {
      "stateAbbreviation": "NC",
      "state": "North Carolina"
    },
    {
      "stateAbbreviation": "ND",
      "state": "North Dakota"
    },
    {
      "stateAbbreviation": "NE",
      "state": "Nebraska"
    },
    {
      "stateAbbreviation": "NH",
      "state": "New Hampshire"
    },
    {
      "stateAbbreviation": "NJ",
      "state": "New Jersey"
    },
    {
      "stateAbbreviation": "NM",
      "state": "New Mexico"
    },
    {
      "stateAbbreviation": "NV",
      "state": "Nevada"
    },
    {
      "stateAbbreviation": "NY",
      "state": "New York"
    },
    {
      "stateAbbreviation": "OH",
      "state": "Ohio"
    },
    {
      "stateAbbreviation": "OK",
      "state": "Oklahoma"
    },
    {
      "stateAbbreviation": "OR",
      "state": "Oregon"
    },
    {
      "stateAbbreviation": "PA",
      "state": "Pennsylvania"
    },
    {
      "stateAbbreviation": "RI",
      "state": "Rhode Island"
    },
    {
      "stateAbbreviation": "SC",
      "state": "South Carolina"
    },
    {
      "stateAbbreviation": "SD",
      "state": "South Dakota"
    },
    {
      "stateAbbreviation": "TN",
      "state": "Tennessee"
    },
    {
      "stateAbbreviation": "TX",
      "state": "Texas"
    },
    {
      "stateAbbreviation": "UT",
      "state": "Utah"
    },
    {
      "stateAbbreviation": "VA",
      "state": "Virginia"
    },
    {
      "stateAbbreviation": "VT",
      "state": "Vermont"
    },
    {
      "stateAbbreviation": "WA",
      "state": "Washington"
    },
    {
      "stateAbbreviation": "WI",
      "state": "Wisconsin"
    },
    {
      "stateAbbreviation": "WV",
      "state": "West Virginia"
    },
    {
      "stateAbbreviation": "WY",
      "state": "Wyoming"
    },
    {
      "stateAbbreviation": "ALCA",
      "state": "Alberta"
    },
    {
      "stateAbbreviation": "BCCA",
      "state": "British Columbia"
    },
    {
      "stateAbbreviation": "MTCA",
      "state": "Manitoba"
    },
    {
      "stateAbbreviation": "NBCA",
      "state": "New Brunswick"
    },
    {
      "stateAbbreviation": "NLCA",
      "state": "Newfoundland and Labrador"
    },
    {
      "stateAbbreviation": "NTCA",
      "state": "Northwest Territories"
    },
    {
      "stateAbbreviation": "NSCA",
      "state": "Nova Scotia"
    },
    {
      "stateAbbreviation": "NVCA",
      "state": "Nunavut"
    },
    {
      "stateAbbreviation": "ONCA",
      "state": "Ontario"
    },
    {
      "stateAbbreviation": "PECA",
      "state": "Prince Edward Island"
    },
    {
      "stateAbbreviation": "QBCA",
      "state": "Quebec"
    },
    {
      "stateAbbreviation": "SKCA",
      "state": "Saskatchewan"
    },
    {
      "stateAbbreviation": "YKCA",
      "state": "Yukon"
    }
   ]

export default function StateSelect({state, setState}: StateSelectProps) {
  
  


  const handleChange = (event: SelectChangeEvent) => {
    setState(event.target.value as string);
  };

  return (
    <Box sx={{ 
        minWidth: 120,
        //padding:"10px",
        paddingBottom:'10px',
        paddingTop:'5px',
        borderBottom: '1px solid silver'
        }}>
      <Grid container spacing={1}>
        <Grid 
            xs={8}
            sx={{
                textAlign:'left'
                }}>
            <Typography
            sx={{
              fontSize:{xs:'1rem', md:'1.2rem'},
              fontWeight:'bold'
            }}>
              Best Offers in {state === 'default' ? "All States" : state}
            </Typography>
            
        </Grid>
        <Grid xs={4}>
            <FormControl fullWidth>
                <InputLabel id="state-select-label">State</InputLabel>
                    <Select
                    labelId="state-select-label"
                    id="state-select"
                    
                    value={state}
                    label="State"
                    onChange={handleChange}
                    >
                    {states.map((item) =>
                    <MenuItem value={item.state} key ={item.state}>
                        {item.state==='default' ? 'All States' : item.state}
                    </MenuItem>
                    )}
                    </Select>
            </FormControl>
    
        </Grid>
    </Grid>
    </Box>
      
  );
}