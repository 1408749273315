import React, { useState } from "react";
import Papa, { ParseResult } from "papaparse"
import ls from 'localstorage-slim';
import './MuiClassNameSetup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OffersTemplate from './OffersTemplate';



export default function RenderOffers({state}:StateProps) {
    

    

    const [parsedCsvData, setParsedCsvData] = React.useState<Values | undefined>();
    const [offerCount, setOfferCount] = React.useState(3);
    const [show, setShow] = useState(true);
      
    const showMore = () => {
      {offerCount === 3 ? setOfferCount(99) : setOfferCount(3);}
      setShow(prev => !prev);

    }
    
    

    React.useEffect(() => {
        const url = process.env.REACT_APP_SHEETS_URL!;

        const getCSV = () => {
            Papa.parse(url, {
            header: true,
            download: true,
            skipEmptyLines: true,
            delimiter: ",",
            complete: (results: ParseResult<Data>) => {
                setParsedCsvData(results)
                cacheSportsbookData(results);
            },
            })
        }
        const cacheSportsbookData = (sportsbookData:Papa.ParseResult<Data>) => {
          const ONEHOUR = 3600;
    
          ls.set('sportsbookData', JSON.stringify(sportsbookData), { ttl: ONEHOUR });
        }
        
        const cachedState = ls.get('sportsbookData');
        
        if (cachedState) {
          setParsedCsvData(JSON.parse(String(cachedState)))
        } else {
          getCSV()

        }
        
        
        
    }, [])
    
    
    
return (
    <Box sx={{ 
            minWidth: 120,
            //padding:"10px",
            //borderBottom: 1
            }}>
        {parsedCsvData && 
        parsedCsvData.data
        .filter(parsedData => parsedData.State.toLowerCase() === state.toLowerCase())
        .sort((a,b) =>  a.Prioritize.toLowerCase() > b.Prioritize.toLowerCase() ? -1 : 1)
        .slice(0,offerCount)
        .map((parsedData, index) =>
        
        <OffersTemplate parsedData={parsedData} index={index}/>
        
        )
        }
         
        <Button variant="text"
                sx={{
                  marginTop:"10px",
                  fontSize:'.75rem'
                }}
                onClick={() => {
                  showMore();
                }}>
            Show {show ? 'More' : 'Less'} Offers
        </Button>
    </Box>

);

}